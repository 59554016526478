// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/auth";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBqAMtoCvTO2Aty6NmMerYaRJrWHIc57s",
  authDomain: "hoxboy-project.firebaseapp.com",
  projectId: "hoxboy-project",
  storageBucket: "hoxboy-project.appspot.com",
  messagingSenderId: "57327283555",
  appId: "1:57327283555:web:bfa1354bb99d7e06d3f756",
  measurementId: "G-WYMV65MDN5"
};

// Initialize Firebase
let app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default firebase;